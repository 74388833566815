<template>
  <div class="container clearfix">
    <div class="float-left">
      <img class="image" src="@/assets//about.jpg" />
    </div>
    <h1 class="emboss">Who I Am</h1>
    <p>
      Genevieve Vickers is a graduate of the SCBWI mentorship program and a
      University of Minnesota alumnus with a degree in journalism, Spanish, and
      creative writing. <em>Amethyst</em> is her debut novel.
    </p>

    <p>
      Having struggled in childhood to find media she could see herself in,
      Genevieve now tells stories that put unconventional protagonists in the
      driver’s seat—protagonists that make those unseen feel seen.
    </p>

    <p>
      Currently calling a small Minnesotan farm home, Genevieve spends her time
      teaching riding, tending to her flock of chickens, reading, drawing,
      practicing spirituality, and, of course, writing. She loves big stompy
      boots, leather jackets, enamel pins and neon hair dye and excels at
      talking too loudly.
    </p>
  </div>
</template>

<style scoped>
@media (min-width: 601px) {
  p {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 50px;
  }

  h1 {
    margin-top: 50px;
  }

  .image {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 25px;
    margin-right: 25px;
  }
}
</style>
